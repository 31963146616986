import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
import { mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      drawer: false,
      direction: 'rtl'
    };
  },
  computed: _objectSpread({}, mapGetters('user', ['sideMode', 'baseColor', 'activeColor', 'mode'])),
  methods: {
    handleClose: function handleClose() {
      this.drawer = false;
    }
  }
};