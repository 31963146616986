var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-cont" },
    [
      _c(
        "el-container",
        {
          class: [
            _vm.isSider ? "openside" : "hideside",
            _vm.isMobile ? "mobile" : "",
          ],
        },
        [
          _c("el-row", {
            class: [_vm.isShadowBg ? "shadowBg" : ""],
            nativeOn: {
              click: function ($event) {
                return _vm.changeShadow()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-aside",
            { staticClass: "main-cont main-left" },
            [
              _c(
                "div",
                {
                  staticClass: "tilte",
                  style: { background: _vm.backgroundColor },
                },
                [
                  _c("img", {
                    staticClass: "logoimg",
                    attrs: { alt: "", src: _vm.$GIN_VUE_ADMIN.appLogo },
                  }),
                  _vm._v(" "),
                  _vm.isSider
                    ? _c(
                        "h2",
                        {
                          staticClass: "tit-text",
                          style: { color: _vm.textColor },
                        },
                        [_vm._v(_vm._s(_vm.$GIN_VUE_ADMIN.appName))]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("Aside", { staticClass: "aside" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-main",
            { staticClass: "main-cont main-right" },
            [
              _c(
                "transition",
                {
                  attrs: {
                    duration: { enter: 800, leave: 100 },
                    mode: "out-in",
                    name: "el-fade-in-linear",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "topfix",
                      style: {
                        width:
                          "calc(100% - " +
                          (_vm.isMobile
                            ? "0px"
                            : _vm.isCollapse
                            ? "54px"
                            : "220px") +
                          ")",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-header",
                            { staticClass: "header-cont" },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 2, lg: 1, md: 1, sm: 1, xl: 1 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "menu-total",
                                      on: { click: _vm.totalCollapse },
                                    },
                                    [
                                      _vm.isCollapse
                                        ? _c("i", {
                                            staticClass: "el-icon-s-unfold",
                                          })
                                        : _c("i", {
                                            staticClass: "el-icon-s-fold",
                                          }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 10,
                                    lg: 14,
                                    md: 14,
                                    sm: 9,
                                    xl: 14,
                                  },
                                },
                                [
                                  _c(
                                    "el-breadcrumb",
                                    {
                                      staticClass: "breadcrumb",
                                      attrs: {
                                        "separator-class":
                                          "el-icon-arrow-right",
                                      },
                                    },
                                    _vm._l(
                                      _vm.matched.slice(1, _vm.matched.length),
                                      function (item) {
                                        return _c(
                                          "el-breadcrumb-item",
                                          { key: item.path },
                                          [_vm._v(_vm._s(item.meta.title))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    lg: 9,
                                    md: 9,
                                    sm: 14,
                                    xl: 9,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fl-right right-box" },
                                    [
                                      _c("Search"),
                                      _vm._v(" "),
                                      _c("Screenfull", {
                                        staticClass: "screenfull",
                                        style: { cursor: "pointer" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "header-avatar",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                            },
                                            [
                                              _c("CustomPic"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.userInfo.nickName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down",
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              staticClass: "dropdown-group",
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-s-custom",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.toPerson.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("个人信息")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-table-lamp",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.LoginOut.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("登 出")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("HistoryComponent"),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { mode: "out-in", name: "el-fade-in-linear" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.$route.meta.keepAlive && _vm.reloadFlag
                        ? _c("router-view", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingFlag,
                                expression: "loadingFlag",
                              },
                            ],
                            staticClass: "admin-box",
                            attrs: { "element-loading-text": "正在加载中" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { mode: "out-in", name: "el-fade-in-linear" } },
                [
                  !_vm.$route.meta.keepAlive && _vm.reloadFlag
                    ? _c("router-view", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingFlag,
                            expression: "loadingFlag",
                          },
                        ],
                        staticClass: "admin-box",
                        attrs: { "element-loading-text": "正在加载中" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("BottomInfo"),
              _vm._v(" "),
              _c("setting"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }